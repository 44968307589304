import { ResponsivePropsOrSingleObject } from "emoreg/types";
import { forTabletUp } from "emoreg/utils/responsive";
import {
  extractBreakpointFromResponsiveProps,
  toResponsiveProps,
} from "emoreg/utils/responsive-props";
import { backgroundApi, BackgroundApiProps } from "emoreg/utils/styles-api/background-api";
import { overflowApi, OverflowApiProps } from "emoreg/utils/styles-api/overflow-api";
import { PaddingApiProps, paddingApi } from "emoreg/utils/styles-api/padding-api";
import { borderApi, BorderRadiusApiProps } from "emoreg/utils/styles-api/radius-api";
import { SizingApiProps, sizingApi } from "emoreg/utils/styles-api/sizing-api";
import { EventApiProps, eventApi } from "emoreg/utils/styles-api/event-api";
import styled from "styled-components";

type BoxApiProps = PaddingApiProps &
  SizingApiProps &
  BackgroundApiProps &
  BorderRadiusApiProps &
  OverflowApiProps &
  EventApiProps;

const boxApi = (props: BoxApiProps) => ({
  ...paddingApi(props),
  ...sizingApi(props),
  ...backgroundApi(props),
  ...borderApi(props),
  ...overflowApi(props),
  ...eventApi(props),
});

export type BoxProps = React.HTMLAttributes<HTMLDivElement> &
  ResponsivePropsOrSingleObject<BoxApiProps>;

export const Box: React.FC<BoxProps> = styled.div(
  props => extractBreakpointFromResponsiveProps(toResponsiveProps(boxApi(props)), "mobile"),
  forTabletUp(props =>
    extractBreakpointFromResponsiveProps(toResponsiveProps(boxApi(props)), "tablet")
  )
);

Box.displayName = "Box";
