import { FormGlobalError } from "oh-errors/error-formatter";
import { Notice } from "../Notice";
import { PropsWithChildren } from "react";

type Props = {
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
};

const Form = ({ children, ...props }: PropsWithChildren<Props>) => {
  return (
    <form
      {...props}
      onSubmit={e => {
        e.preventDefault();
        props.onSubmit?.(e);
      }}
    >
      {children}
    </form>
  );
};

const GlobalError = ({ globalError }: { globalError?: FormGlobalError | null }) => {
  if (!globalError) return null;
  return <Notice type="red">{globalError.verbose}</Notice>;
};

Form.GlobalError = GlobalError;

export { Form };
