import {
  AgencyUuid,
  CaregiverUuid,
  Gender,
  MonetBillUuid,
  MonetPaymentUuid,
  PayslipXimiId,
  ProclientMemberUuid,
  ProclientUuid,
  RegularizationUuid,
  ThirdPartyPayerUuid,
} from "hassibot/services_v2/common/types";
import { DateTime } from "luxon";

import {
  MonetBillInterventionComputed,
  MonetBillInterventionDeltaEnriched,
  MonetBillInterventionDigestComputed,
  MonetBillInterventionEnriched,
  MonetBillInterventionInput,
} from "hassibot/services_v2/monet/bills/types/interventions";
import {
  MonetBillDigestOhpProductsComputed,
  MonetBillDigestOhpProductsEnriched,
  MonetOhPComputed,
  MonetOhPEnriched,
  MonetOhPInput,
} from "hassibot/services_v2/monet/bills/types/ohp";
import { MonetBillPricingStrategy } from "hassibot/services_v2/monet/bills/types/pricing-strategy";
import {
  AmountInCents,
  CalendarSurchargeType,
  XimiProductType,
} from "hassibot/services_v2/monet/common/types";
import { MonetPaymentBreakdown } from "hassibot/services_v2/monet/payments/types";
import {
  MonetBillRegularizationEnriched,
  MonetBillRegularizationInput,
} from "hassibot/services_v2/monet/regularizations/types";
import { MonetBillSideEffects } from "./side-effects";

export enum MonetBillStatus {
  draft = "draft",
  waitingForApproval = "waiting_for_approval",
  waitingForSending = "waiting_for_sending",
  sendingInProgress = "sending_in_progress",
  sendingFailure = "sending_failure",
  sent = "sent",
}
export const lockedBillStatuses = [
  MonetBillStatus.sendingInProgress,
  MonetBillStatus.sendingFailure,
  MonetBillStatus.sent,
];
export const isBillLocked = (billStatus: MonetBillStatus): boolean =>
  lockedBillStatuses.includes(billStatus);

export enum CaregiverPayslipStatus {
  draft = "draft",
  sent = "sent",
}
export type CaregiverPayslip = {
  caregiverUuid: CaregiverUuid;
  caregiverGrossSalary: number;
  employerContributions: number;
  ximiId: PayslipXimiId;
  payDate: DateTime;
  reference: string;
  status: CaregiverPayslipStatus | null;
};
export enum MonetBillBreakdownStatus {
  paid = "paid",
  partiallyPaid = "partially_paid",
  unpaid = "unpaid",
}
type TransientMonetPaymentBreakdown = { monetBillUuid: MonetBillUuid; amountCents: number };

export enum MonetBillSource {
  ximi = "ximi",
  monet = "monet",
}

export enum MonetBillType {
  normalBill = "normal_bill",
  parentVirtualBill = "parent_virtual_bill",
  childBillProclient = "child_bill_proclient",
  childBillThirdParty = "child_bill_third_party",
}
export const isChildBill = (billType: MonetBillType): boolean =>
  billType === MonetBillType.childBillProclient || billType === MonetBillType.childBillThirdParty;

export type MonetBillsByUuid = {
  [id: MonetBillUuid]: MonetBillStatus;
};

export const PROFORMA_REFERENCE_NUMBER = "PROFORMA";

export type MonetBillShort = {
  uuid: MonetBillUuid;
  agencyUuid: AgencyUuid;
  emittedOn: DateTime | null;
  mainPeriodStart: DateTime;
  mainPeriodEnd: DateTime;
  adjustmentsPeriodStart: DateTime;
  referenceNumber: string;
  proclientUuid: ProclientUuid;
  proclientMemberUuid: ProclientMemberUuid;
  status: MonetBillStatus;
  issues: MonetBillIssues;
  paymentStatus: MonetBillBreakdownStatus | null;
  ximiId?: number;
  monetPaymentBreakdowns: TransientMonetPaymentBreakdown[];
  source: MonetBillSource;
  billType: MonetBillType;
  parentVirtualBillUuid: MonetBillUuid | null;
  thirdPartyPayerUuid: ThirdPartyPayerUuid | null;
  totalAmountCents: number;
  totalUnpaidCents: number;
  marginRate: number | null;
  futurePaymentSideEffect: FuturePaymentSideEffectType;
};

export type CommonMonetBillInput = MonetBillShort & {
  userProvidedInput: MonetBillUserProvidedInput;
  sideEffectsRuns: MonetBillSideEffects[];
  sideEffectsLastRefresh: MonetBillSideEffects | null;
};

export type MonetBillInput = CommonMonetBillInput & {
  input: {
    pricingStrategies: MonetBillPricingStrategy[];
    relevantRegularizations: MonetBillRegularizationInput[];
    ohpProducts: MonetOhPInput;
    mainPeriodInterventions: MonetBillInterventionInput[];
    // On va comparer avec l'output de la précédente facture, d'où le "computed"
    adjustmentsPeriodCurrentInterventions: MonetBillInterventionInput[];
    adjustmentsPeriodPreviousInterventions: MonetBillInterventionComputed[];
    caregiversPayslips: CaregiverPayslip[];
    caregiversIdentities: MonetBillCaregiverIdentity[];
  };
};
export type MonetBillInputIssued = CommonMonetBillInput & {
  input: null;
};
export type MaybeMonetBillInput = MonetBillInput | MonetBillInputIssued;

export type MonetBillCaregiverIdentity = {
  [uuid: CaregiverUuid]: {
    caregiverLastName: string;
    caregiverFirstName: string;
    caregiverGender: Gender;
  };
};
export type MonetBillComputedContent = {
  mainPeriodInterventions: MonetBillInterventionComputed[];
  adjustmentsPeriodCurrentInterventions: MonetBillInterventionComputed[];
  appliedRegularizations: MonetBillRegularizationInput[];
  adjustmentsPeriodInterventionDelta: MonetBillInterventionDigestComputed[];
  ohpProducts: MonetOhPComputed;
  totalCaregiversSuperGrossSalary: AmountInCents;
  digest: MonetBillDigestComputed;
  paymentBreakdowns: MonetPaymentBreakdown[];
};
export type MonetBillComputed = MonetBillInput & {
  computed: MonetBillComputedContent;
};
type MonetXimiBillProduct = {
  calendarSurchargeType: CalendarSurchargeType | null;
  isNightSurcharged: boolean;
  pricingStrategyVersion: "XIMI";
  productName: string;
  productType: XimiProductType;
  quantity: number;
  totalAmountCents: number;
  unitAmountCents: number;
};
export type MonetXimiBillComputed = MonetBillComputed & {
  source: MonetBillSource.ximi;
  computed: MonetBillComputedContent & {
    digest: MonetBillDigestComputed & { products: MonetXimiBillProduct[]; vat: number };
  };
};

export type MonetBillBreakable = {
  uuid: MonetBillUuid;
  mainPeriodStart: DateTime;
  mainPeriodEnd: DateTime;
  adjustmentsPeriodStart: DateTime;
  emittedAt: DateTime | null;
  referenceNumber: string;
  proclientMemberUuid: ProclientMemberUuid;
  proclientUuid: ProclientUuid;
  status: MonetBillStatus;
  checkedPaymentsUuids: MonetPaymentUuid[];
  totalAmountCents: AmountInCents;
  totalBreakdownCents: AmountInCents;
  totalUnpaidCents: AmountInCents;
  paymentBreakdowns: MonetPaymentBreakdown[];
  billType: MonetBillType;
};

export type MonetBillComputedIssued = MonetBillInputIssued & {
  computed: null;
};
export type MaybeMonetBillComputed = MonetBillComputed | MonetBillComputedIssued;

export type MonetBillEnriched = MonetBillComputed & {
  enriched: {
    mainPeriodInterventions: MonetBillInterventionEnriched[];
    adjustmentsPeriodInterventionsDelta: MonetBillInterventionDeltaEnriched[];
    relevantRegularizations: MonetBillRegularizationEnriched[];
    ohpProducts: MonetOhPEnriched[];
    appliedRegularizations: MonetBillRegularizationEnriched[];
    digest: MonetBillDigestEnriched;
  };
};
export type MonetBillEnrichedIssued = MonetBillComputedIssued & {
  enriched: null;
};
export type MaybeMonetBillEnriched = MonetBillEnriched | MonetBillEnrichedIssued;

export type MonetBillUserProvidedInput = {
  optedOutMainPeriodRegularizationsUuids: RegularizationUuid[];
  optedInOtherPeriodsRegularizationsUuids: RegularizationUuid[];
  adminApprovalLock: boolean; // This value is only used by the backend but it needs to be kept at this time in the frontend code base. I'll be passed to the worker after an interaction (like add or remove a regularization). This key isn't put to the backend after an interaction (only in the worker).
};
type MonetBillDigestRegularizationComputed = {
  regularizationType: string;
  billAmountCents: number;
  totalAmountCents: number;
  category: string;
  quantity: number;
};
type MonetBillDigestComputed = {
  mainPeriodInterventions: MonetBillInterventionDigestComputed[];
  ohpProducts: MonetBillDigestOhpProductsComputed;
  adjustmentsPeriodCurrentInterventions: MonetBillInterventionDigestComputed[];
  regularizations: MonetBillDigestRegularizationComputed[];
  reducedVat: number;
  regularVat: number;
  totalAmountExcludingTaxCents: number;
  totalAmountCents: number;
  marginRate: number;
};

type MonetBillDigestProductEnriched = {
  productLabel: string;
  durationLabel: string;
  startAtLabel: string;
  amountLabel: string;
  categoryLabel: string;
  quantityLabel: string;
  unitAmountLabel: string;
  totalAmountLabel: string;
};

type MonetBillDigestEnriched = {
  mainPeriodInterventions: MonetBillDigestProductEnriched[];
  adjustmentsPeriodInterventions: MonetBillDigestProductEnriched[];
  regularizations: MonetBillDigestProductEnriched[];
  kilometricAllowance: MonetBillDigestProductEnriched[];
  adjustmentsKilometricAllowance: MonetBillDigestProductEnriched[];
  ohpProducts: MonetBillDigestOhpProductsEnriched[];
  regularVatLabel: string;
  reducedVatLabel: string;
  totalAmountExcludingTaxLabel: string;
  totalAmountLabel: string;
};

export type MonetBillIssue = { type: string; extra: Record<string, string> | null };
export enum MonetBillIssueCategory {
  errors = "errors",
  warnings = "warnings",
  blockings = "blockings",
}
export type MonetBillIssues = {
  [MonetBillIssueCategory.errors]: MonetBillIssue[];
  [MonetBillIssueCategory.warnings]: MonetBillIssue[];
  [MonetBillIssueCategory.blockings]: MonetBillIssue[];
};

export const monetBillShortHasErrorIssued = (monetBillShort: MonetBillShort): boolean =>
  monetBillShort.issues[MonetBillIssueCategory.errors].length > 0;
export const isMonetBillComputed = (
  monetBillComputed: MaybeMonetBillComputed
): monetBillComputed is MonetBillComputed =>
  monetBillComputed.issues[MonetBillIssueCategory.errors].length === 0;
export const monetBillEnrichedHasErrorIssued = (
  monetBillEnriched: MaybeMonetBillEnriched
): monetBillEnriched is MonetBillEnrichedIssued =>
  monetBillEnriched.issues[MonetBillIssueCategory.errors].length > 0;

export const monetBillHasIssues = (monetBillEnriched: MaybeMonetBillEnriched): boolean =>
  Object.values(monetBillEnriched).flat().length > 0;

export const REGULAR_VAT = "regular_vat";
export const REDUCED_VAT = "reduced_vat";

export const REGULARIZATION_VAT_RATE = {
  regular_vat: "20%",
  reduced_vat: "5,5%",
};

export enum FuturePaymentSideEffectType {
  NO_FUTURE_PAYMENT = "no_future_payment",
  PAYMENT_INTENT_ALREADY_CREATED = "payment_intent_already_created",
  TAX_CREDIT_PAYMENT_REQUEST_ALREADY_CREATED = "tax_credit_payment_request_already_created",
  PAYMENT_INTENT_WILL_BE_CREATED = "payment_intent_will_be_created",
  TAX_CREDIT_PAYMENT_REQUEST_WILL_BE_CREATED = "tax_credit_payment_request_will_be_created",
  MISSING_CESU_EXCLUSION = "missing_cesu_exclusion",
  OTHER_EXCLUSION = "other_exclusion",
}

export const futurePaymentSideEffectTypesForPaidBills = [
  FuturePaymentSideEffectType.NO_FUTURE_PAYMENT,
  FuturePaymentSideEffectType.PAYMENT_INTENT_ALREADY_CREATED,
  FuturePaymentSideEffectType.TAX_CREDIT_PAYMENT_REQUEST_ALREADY_CREATED,
];
