import amplitude from "amplitude-js";
import appConfig from "./app";

export const amplitudeInstance = amplitude.getInstance();

export const initAmplitude = (deviceId: string) => {
  amplitudeInstance.init(import.meta.env.VITE_AMPLITUDE);
  amplitudeInstance.setDeviceId(deviceId);
  amplitudeInstance.setVersionName(appConfig.BUILD_HASH);
};
