import { useEffect, useState } from "react";

/** keep track of whether or not a html element is scrollable to top or bottom */
export const useIsScrollable = (ref: React.MutableRefObject<HTMLDivElement | null>) => {
  const [isAtTop, setIsAtTop] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!ref.current) return;
    const { clientHeight, scrollHeight, scrollTop } = ref.current;
    setIsAtTop(scrollTop === 0);
    setIsAtBottom(scrollTop + clientHeight >= scrollHeight);
  });

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { clientHeight, scrollHeight, scrollTop } = e.currentTarget;
    setIsAtTop(scrollTop === 0);
    setIsAtBottom(scrollTop + clientHeight >= scrollHeight);
  };

  return [isAtTop, isAtBottom, onScroll] as const;
};
