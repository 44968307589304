import { Flex, FlexProps } from "emoreg/layouts";
import { isTruthy } from "hassibot/util/guards";
import React, { PropsWithChildren } from "react";
import { GroupedCheckboxContext } from "../context";
import { FieldContainer, InputErrorMessage } from "emoreg";

export type GroupedCheckboxContainerProps<T extends string> = PropsWithChildren<{
  // FIXME: decomment once labrel are handled differently
  // label?: string;
  // required?: boolean;
  name: string;
  values?: T[] | null;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onChangeValues?: (value: T[]) => void;
  direction?: FlexProps["direction"];
  hasError?: boolean;
  error?: string;
  style?: React.CSSProperties;
  className?: string;
}>;

export const Container = <T extends string>({
  style,
  className,
  // FIXME: decomment once labrel are handled differently
  // label,
  // required,
  name,
  values,
  onChange,
  onChangeValues,
  direction,
  hasError,
  error,
  children,
}: GroupedCheckboxContainerProps<T>) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(e);
    // extract original id from input id
    const id = e.target.id.substring(name.length + 1);
    // if value is contained inside already picked values, remove it
    if (values?.includes(id as T)) return onChangeValues?.(values.filter(v => v !== id));
    // else, add it
    return onChangeValues?.([...(values ?? []), id as T]);
  };

  return (
    <GroupedCheckboxContext.Provider
      value={{
        name,
        hasError: hasError || !!error,
        onChange: handleChange,
        values: values ?? [],
        "aria-labelledby": [`label_${name}`, !!error && `${error}_${name}`]
          .filter(isTruthy)
          .join(" "),
      }}
    >
      <FieldContainer style={style} className={className}>
        {/* {label && (
          <InputLabel id={`label_${name}`} required={required}>
            {label}
          </InputLabel>
        )} */}
        {/* this layer could be redefined every time by user.
          by keeping it here, we ensure consistency accross all gaps */}
        <Flex wrap="wrap" direction={direction} gap={8}>
          {children}
        </Flex>
        {error && <InputErrorMessage id={`${error}_$name`}>{error}</InputErrorMessage>}
      </FieldContainer>
    </GroupedCheckboxContext.Provider>
  );
};
