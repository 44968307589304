import { color } from "emoreg/const";
import { Flex } from "emoreg/layouts";
import { rem } from "polished";
import styled from "styled-components";

const Container = styled(Flex)({
  "&::-webkit-scrollbar": {
    width: 0,
    height: 0,
  },
});

const Label = styled.label(({ checked }) => ({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: rem(32),
  fontSize: 16,
  borderRadius: 8,
  cursor: "pointer",
  transition: "background-color 125ms ease-in-out, color 125ms ease-in-out",
  // FIXME: remove once unknown global style is fixed
  margin: 0,

  color: checked ? color.white : color.grey.darker,
  backgroundColor: checked ? color.blue.base : "transparent",

  "&:hover": {
    color: checked ? color.white : color.grey.darker,
    backgroundColor: checked ? color.blue.light : color.blue.lighter,
  },
}));

const Input = styled.input({
  position: "absolute",
  maxHeight: 0,
  maxWidth: 0,
  overflow: "hidden",
});

const ScrollIndicator = styled.div(({ reversed }) => ({
  width: "100%",
  height: rem(64),
  background: `linear-gradient(to ${reversed ? "top" : "bottom"}, transparent 0%, white 100%)`,
}));

export const TimePickerStyle = { Container, Label, Input, ScrollIndicator };
