import React, { forwardRef } from "react";
import { TogglePillStyle } from "./styles";

export type TogglePillProps = {
  checked?: boolean;
  onChangeValue?: (value: boolean) => void;
} & Omit<React.HTMLProps<HTMLInputElement>, "checked" | "type" | "style">;

export const TogglePill = forwardRef<HTMLInputElement, React.PropsWithChildren<TogglePillProps>>(
  ({ checked = false, children, onChange, onChangeValue, className, ...props }) => {
    const handleChange = event => {
      onChange?.(event);
      onChangeValue?.(event.target.checked);
    };

    return (
      <TogglePillStyle className={className}>
        <TogglePillStyle.Input
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          {...props}
        />
        {children}
      </TogglePillStyle>
    );
  }
);

TogglePill.displayName = "TogglePill";
