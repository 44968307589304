import { color } from "emoreg/const";
import { rem } from "polished";
import styled from "styled-components";

const HeaderDay = styled.span({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: rem(16),
  color: color.grey.dark,
  height: rem(36),
  textAlign: "center",
  verticalAlign: "middle",
  flex: 1,
});

const WeekDay = styled.button<{
  isSelectedStart?: boolean;
  isSelectedEnd?: boolean;
  isTargetMonth?: boolean;
  isSelected?: boolean;
  isPast?: boolean;
  isInBetween?: boolean;
}>(
  ({ isSelectedStart, isSelectedEnd, isInBetween, isPast }) => ({
    position: "relative",
    flex: 1,
    fontSize: rem(16),
    height: rem(36),
    padding: 0,
    margin: 0,
    borderTopLeftRadius: (isSelectedEnd && !isSelectedStart) || isInBetween ? 0 : 5,
    borderTopRightRadius: (isSelectedStart && !isSelectedEnd) || isInBetween ? 0 : 5,
    borderBottomLeftRadius: (isSelectedEnd && !isSelectedStart) || isInBetween ? 0 : 5,
    borderBottomRightRadius: (isSelectedStart && !isSelectedEnd) || isInBetween ? 0 : 5,
    border: "none",
    textDecoration: isPast ? "line-through" : "none",
  }),
  ({ isSelected, isTargetMonth, isInBetween }) => {
    if (isInBetween) {
      return {
        color: color.blue.base,
        backgroundColor: color.blue.lighter,
        "&:hover": { backgroundColor: color.blue.lighter },
      };
    }
    if (isSelected) {
      return {
        color: color.white,
        backgroundColor: color.blue.base,
        "&:hover": { backgroundColor: color.blue.light },
      };
    }
    if (isTargetMonth) {
      return {
        color: color.grey.darker,
        backgroundColor: color.white,
        "&:hover": { backgroundColor: color.blue.lighter },
      };
    }
    return {
      color: color.grey.base,
      backgroundColor: color.white,
      "&:hover": { backgroundColor: color.blue.lighter },
    };
  }
);

const TodayIndicator = styled.div({
  border: `1px solid ${color.orange.base}`,
  position: "absolute",
  inset: 0,
  borderRadius: 5,
});

export const DatePickerStyle = {
  HeaderDay,
  WeekDay,
  TodayIndicator,
};

const DateSelectorButton = styled.button({
  backgroundColor: "transparent",
  border: "none",
  height: rem(32),
  width: rem(32),
  borderRadius: 5,
  "&:hover": {
    cursor: "pointer",
    backgroundColor: color.blue.lighter,
  },
});

const DateSelectorTitle = styled.div({
  fontSize: rem(16),
  color: color.grey.darker,
  fontWeight: 500,
});

export const DateSelectorStyle = {
  Button: DateSelectorButton,
  Title: DateSelectorTitle,
};
