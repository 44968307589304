import { Checkbox, CheckboxProps } from "emoreg/molecules";
import { forwardRef, useContext } from "react";
import { GroupedCheckboxContext } from "../context";

export type CheckboxItemProps = Omit<
  CheckboxProps,
  "value" | "checked" | "id" | "hasError" | "error" | "aria-labelledby" | "onChange"
> & { value: string };

export const CheckboxItem = forwardRef<HTMLInputElement, CheckboxItemProps>(
  ({ value, ...props }, ref) => {
    const ctx = useContext(GroupedCheckboxContext);

    return (
      <Checkbox
        {...props}
        ref={ref}
        id={`${ctx.name}_${value}`}
        hasError={ctx.hasError}
        checked={ctx.values.includes(value)}
        aria-labelledby={ctx["aria-labelledby"]}
        onChange={ctx.onChange}
      />
    );
  }
);
