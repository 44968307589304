export const computeTimeRangeValues = (start: number, end: number, step: number) => {
  if (start > end) throw new Error("start must be lower or equal to end");
  const values = [start];
  while (values[values.length - 1] + step <= end) values.push(values[values.length - 1] + step);
  return values;
};

export const minutesToInputTime = (minutes: number) => {
  const hours = String(Math.floor(minutes / 60));
  const remainingMinutes = String(minutes % 60);
  return `${hours}:${remainingMinutes.padStart(2, "0")}`;
};

export const inputTimeToMinutes = (time: string) => {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
};
