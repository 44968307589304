import { OhApi } from "siayuda";
import * as Sentry from "@sentry/react";

import { deleteUserCredentials, setUserCredentials } from "token-manager";
import appConfig from "@config/app";

import { client } from "hassibot/services_v2";

import { routes } from "hassibot/services_v2/login/routes";
import { loginReader, sessionCollaboratorReader } from "hassibot/services_v2/login/reader";
import { loginWriter, startSessionWriter } from "hassibot/services_v2/login/writer";
import { AuthToken, SessionCollaborator } from "hassibot/services_v2/login/types";
import { DeviceId } from "hassibot/services_v2/common/types";

const authLessClient = new OhApi.ApiV2Client({ auth: null });

const apiLogin = (jwtToken: string, deviceId: DeviceId): Promise<OhApi.ApiResponse<AuthToken>> =>
  authLessClient
    .postJson(routes.login(), loginWriter({ deviceId }), {
      Authorization: `Bearer ${jwtToken}`,
    })
    .then(OhApi.applyReader(loginReader));

/*
 * Trade a Google JWT Token for a Ouihelp Auth Token with the Ouihelp API.
 */
const tradeJWTWithApi = (jwt: string): Promise<string> => {
  if (appConfig.DEVICE_ID) {
    return apiLogin(jwt, appConfig.DEVICE_ID).then(response => {
      if (OhApi.isSuccess(response)) {
        return response.value.authToken;
      }
      const error = new Error(`Login server response was not a success`);

      Sentry.withScope(scope => {
        scope.setExtra("Error Kind", response.kind);
        if (
          OhApi.isClientError(response) ||
          OhApi.isGlobalError(response) ||
          OhApi.isUnrecoverableHttpError(response)
        ) {
          scope.setExtra("Error Status Code", response.statusCode);
          scope.setExtra("Error Request ID", response.headers.get("x-request-id"));
        }

        Sentry.captureException(error);
      });

      if (OhApi.isUnrecoverableHttpError(response) && response.error.error) {
        throw new Error(response.error.error);
      }

      throw error;
    });
  } else {
    const error = new Error(`No "device ID" set`);
    Sentry.captureException(error);
    throw error;
  }
};

export const digestGoogleAuthLogin = ({ mail, googleId, googleJwt }): Promise<void | Error> => {
  /**
   * This function is executed right after a login.
   *
   * The function call OH api to trade the freshly fetched Google JWT
   * for a Ouihelp token which is then stored in IndexedDB.
   */
  return tradeJWTWithApi(googleJwt)
    .then(token => setUserCredentials({ token, email: mail, id: googleId }))
    .catch(error => error);
};

export const startSession = (pushToken: string): Promise<OhApi.ApiResponse<SessionCollaborator>> =>
  client
    .postJson(routes.startSession(), startSessionWriter({ pushToken: pushToken }))
    .then(OhApi.applyReader(sessionCollaboratorReader));

const apiLogout = (): Promise<OhApi.ApiResponse<undefined>> => client.postJson(routes.logout(), {});

export const logout = (): Promise<void> =>
  apiLogout()
    .catch()
    .then(() => {
      deleteUserCredentials();
    });
