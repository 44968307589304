import { Position } from "emoreg/layouts";
import { computeTimeRangeValues, inputTimeToMinutes, minutesToInputTime } from "./utils";
import { TimePickerStyle } from "./styles";
import { useImperativeHandle, useRef } from "react";
import React from "react";
import { rem } from "polished";
import { useIsScrollable } from "emoreg/hooks";

export type TimePickerProps = {
  /** input id */
  id: string;
  /** classname, usable to overload component styling */
  className?: string;
  /** whether or not time value is required */
  required?: boolean;
  /** value in seconds */
  value?: number | null;
  /** value change event handler */
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  /** value change handler (only expose new value) */
  onChangeValue?: (value: number) => void;
  /** min value in seconds */
  min?: number;
  /** max value in seconds */
  max?: number;
  /** time between two selectables values in seconds */
  step?: number;
};

export type TimePickerRefApi = {
  /** allow scrolling to a specific time */
  scrollTo: (value: number, behavior?: ScrollBehavior) => void;
};

export const TimePicker = React.forwardRef<TimePickerRefApi, TimePickerProps>(
  (
    {
      id,
      className,
      required,
      value,
      onChange,
      onChangeValue,
      min = 0,
      max = 60 * 24 - 1,
      step = 15,
    },
    ref
  ) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [isAtTop, isAtBottom, onScroll] = useIsScrollable(containerRef);
    const options = computeTimeRangeValues(min, max, step);

    // handle input change and call related event handler (raw and refined)
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputTime = e.target.value;
      const minutes = inputTimeToMinutes(inputTime);
      onChange?.(e);
      onChangeValue?.(minutes);
    };

    // allow scrolling to a specific time (scroll center targets time when possible)
    const scrollTo = (minutes: number, behavior?: ScrollBehavior) => {
      containerRef.current?.scroll({
        top:
          (containerRef.current.scrollHeight * (minutes + step / 2 - min)) / (max - min) -
          containerRef.current.clientHeight / 2,
        behavior,
      });
    };

    // expose scrollTo method to parent through ref
    useImperativeHandle(ref, () => ({ scrollTo }));

    return (
      <TimePickerStyle.Container
        ref={containerRef}
        className={className}
        w={rem(114)}
        gap={4}
        direction="column"
        onScroll={onScroll}
        overflow="auto"
      >
        {!isAtTop && (
          <Position pointerEvents="none" position="sticky" zIndex={1} top={0} h={0}>
            <TimePickerStyle.ScrollIndicator reversed />
          </Position>
        )}
        {options.map(option => {
          const inputTime = minutesToInputTime(option);
          return (
            <TimePickerStyle.Label checked={value === option} key={inputTime}>
              <TimePickerStyle.Input
                required={required}
                onChange={handleChange}
                checked={value === option}
                value={inputTime}
                type="radio"
                name="id"
                id={`${id}_${inputTime}`}
              />
              {inputTime}
            </TimePickerStyle.Label>
          );
        })}
        {!isAtBottom && (
          <Position
            pointerEvents="none"
            position="sticky"
            zIndex={1}
            bottom={0}
            h={0}
            style={{ display: "flex", alignItems: "end" }}
          >
            <TimePickerStyle.ScrollIndicator />
          </Position>
        )}
      </TimePickerStyle.Container>
    );
  }
);
