import { datadogRum } from "@datadog/browser-rum";
import appConfig from "./app";

export const initDatadogRum = () =>
  datadogRum.init({
    applicationId: appConfig.DATADOG_APPLICATION_ID,
    clientToken: appConfig.DATADOG_CLIENT_TOKEN,
    site: "datadoghq.com",
    service: "hassibot",
    env: "prod",
    sessionSampleRate: 10,
    sessionReplaySampleRate: 5,
    defaultPrivacyLevel: "mask-user-input",
  });
