import appConfig from "@config/app";

import { prependUri } from "hassibot/services_v2/common";

class LoginRoutes {
  startSession = (): string => "hassibot/start-session";
  login = (): string => "hassibot/login";
  logout = (): string => "hassibot/logout";
}

export const routes = prependUri(appConfig.API_BASE_URL + "/2/collaborators/")(new LoginRoutes());
