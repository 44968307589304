import { CustomStore, del as delIdb, get as getIdb, set as setIdb } from "custom-idb-keyval";
import { DateTime, Duration } from "luxon";
import { OhApi } from "siayuda";

import appConfig from "@config/app";
import { LegalEntity } from "hassibot/services_v2/common/types";
import { ValueOf } from "types";

// We need to know beforehand what the store names are going to be
export enum ALL_ENTITY_STORES_NAMES {
  CaregiversFullEntities = "caregivers_full",
  StructureEntities = "structures-identities",
  ProclientsFullEntities = "proclients-full",
  PrescribersFullEntities = "prescribers-identities",
  StaticEntries = "static",
}

interface WrappedEntity<EntityType> {
  hassibotBuildHash: string;
  data: EntityType;
  createdAt: string; // iso
}

export type CacheEvictionStrategy = <T>(entry: WrappedEntity<T>) => boolean;

function wrapEntity<EntityType>(entity: EntityType): WrappedEntity<EntityType> {
  return {
    data: entity,
    hassibotBuildHash: appConfig.BUILD_HASH,
    createdAt: DateTime.local().toISO(),
  };
}

const validateWrappedEntityOrUndefined =
  <EntityType>(shouldEvict: CacheEvictionStrategy) =>
  (idbWrappedEntity: WrappedEntity<EntityType> | undefined): EntityType | null => {
    if (idbWrappedEntity !== undefined && !shouldEvict(idbWrappedEntity)) {
      return idbWrappedEntity.data;
    } else {
      return null;
    }
  };

export class SimpleObjectStore<K extends string, T> {
  store: CustomStore;
  defaultEvictionStrategy: CacheEvictionStrategy;

  // We will store "in flight" fetch requests (wrapped in `creator`) in
  // this map to avoid sending them multiple times for the same entity.
  reentrantPromises: Map<K, Promise<OhApi.ApiResponse<T>>>;

  constructor(
    storeName: ValueOf<ALL_ENTITY_STORES_NAMES>,
    legalEntity: LegalEntity,
    // TODO(@chtr): better typing here
    stores: string[],
    defaultEvictionStrategy: CacheEvictionStrategy = appVersion
  ) {
    const databaseName = `hb-entities-${legalEntity.name.replaceAll(" ", "-").toLowerCase()}-${legalEntity.uuid}`;
    this.store = new CustomStore(databaseName, storeName as string, Object.values(stores));
    this.defaultEvictionStrategy = defaultEvictionStrategy;

    this.reentrantPromises = new Map<K, Promise<OhApi.ApiResponse<T>>>();
  }

  private get = (key: K, cacheEvictionStrategy?: CacheEvictionStrategy): Promise<T | null> =>
    getIdb<WrappedEntity<T>>(key, this.store).then(wrappedEntityOrUndefined => {
      return validateWrappedEntityOrUndefined<T>(
        cacheEvictionStrategy || this.defaultEvictionStrategy
      )(wrappedEntityOrUndefined);
    });

  set = (key: K, entity: T): Promise<void> => setIdb(key, wrapEntity(entity), this.store);
  del = (key: K): Promise<void> => delIdb(key, this.store);

  getOrCreate = (
    key: K,
    creator: () => Promise<OhApi.ApiResponse<T>>,
    cacheEvictionStrategy?: CacheEvictionStrategy
  ): Promise<OhApi.ApiResponse<T>> => {
    const maybePromise = this.reentrantPromises.get(key);
    if (maybePromise) {
      return maybePromise;
    }
    const promise = this.get(key, cacheEvictionStrategy).then(hit => {
      if (hit) {
        return OhApi.fakeApiResponse<T>(200, hit);
      } else {
        return creator().then(response => {
          if (OhApi.isSuccess(response)) {
            return this.set(key, response.value).then(
              () => response,
              err => {
                console.warn(`Insertion failed for key ${key}`, err);
                return response;
              }
            );
          } else {
            return Promise.resolve(response as OhApi.ApiResponse<T>);
          }
        });
      }
    });

    // Register this "in flight request" and schedule its removal once
    // the request is done (and once its result is stored in
    // IndexedDB).
    this.reentrantPromises.set(key, promise);
    promise.finally(() => {
      this.reentrantPromises.delete(key);
    });

    return promise;
  };
}

/**
 * Default cache eviction strategy in which we "evict" data
 * if it has been generated by another Hassibot version (commit)
 */
export const appVersion: CacheEvictionStrategy = <T>(e?: WrappedEntity<T> | null) =>
  !e || e.hassibotBuildHash !== appConfig.BUILD_HASH;

/**
 * Cache eviction strategy in which we "evict" data
 * if it is older than the given duration
 */
const timeBased =
  (ttl: Duration): CacheEvictionStrategy =>
  <T>(e?: WrappedEntity<T> | null): boolean =>
    !e || DateTime.fromISO(e.createdAt).plus(ttl) < DateTime.local();

const oneDay = Duration.fromObject({ days: 1 });

/**
 * Cache eviction strategy in which we "evict" data
 * in case of a "page reload" (F5 or CTRL+R)
 */
const pageReload: CacheEvictionStrategy = <T>(_e?: WrappedEntity<T> | null) =>
  window && window.performance.navigation.type === window.performance.navigation.TYPE_RELOAD;

const or =
  (...cacheEvictionStrategies: CacheEvictionStrategy[]): CacheEvictionStrategy =>
  <T>(e?: WrappedEntity<T> | null): boolean =>
    !e || cacheEvictionStrategies.some(s => s(e));

/**
 * Construct and return a "classic" time based cache eviction strategy
 * in which we "evict" data in the following ORed cases:
 *    - It has been generated by another Hassibot version (commit)
 *    - We're in a "page reload" (F5 or CTRL+R)
 *    - The data is older than the given duration
 */
export const makeClassicTimeBasedCacheEvictionStrategy = (
  ttl: Duration = oneDay
): CacheEvictionStrategy => or(timeBased(ttl), appVersion, pageReload);
