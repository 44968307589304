import { color, spaces } from "emoreg/const";
import styled from "styled-components";

const Container = styled.label`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  color: ${color.grey.dark};
  background-color: ${color.grey.light};

  &:has(input:checked) {
    color: ${color.blue.base};
    background-color: ${color.blue.lighter};
  }

  /* FIXME: There's a mismatch between design and code for padding. 
     This may need adjustment after design review. */
  padding: ${spaces[2]} ${spaces[8]};

  /** FIXME: remove once global styles are removed */
  margin: 0;

  cursor: pointer;
  transition: all 0.2s ease;
`;

Container.baseName = "TogglePillContainer";

const Input = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  pointer-events: none;
`;

Input.baseName = "TogglePillInput";

export const TogglePillStyle = Object.assign(Container, { Input });
