import * as Sentry from "@sentry/react";
import appConfig from "./app";

export const initSentryWebApp = () =>
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    ...(appConfig.BUILD_HASH && {
      release: appConfig.BUILD_HASH,
      integrations: [Sentry.browserTracingIntegration()],
    }),
    ...(appConfig.IS_TEST_ENVS && { environment: "test-envs" }),
    ignoreErrors: ["Non-Error promise rejection captured with value: [Screeb]"],
    tracesSampleRate: 0.2,
  });

export const initSentryWebworker = () =>
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    ...(appConfig.BUILD_HASH && { release: appConfig.BUILD_HASH }),
  });

export const initSentryServiceWorker = () =>
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    ...(appConfig.BUILD_HASH && { release: appConfig.BUILD_HASH }),
    ...(appConfig.IS_TEST_ENVS && { environment: "test-envs" }),
  });
