import {
  CheckableInputLabel,
  CheckboxInput,
  CheckboxInputProps,
  FieldContainer,
  InputErrorMessage,
} from "emoreg";
import { SubLabel } from "emoreg/atoms/Inputs/common";
import { Box, Flex } from "emoreg/layouts";
import { isTruthy } from "hassibot/util/guards";
import React from "react";

export type CheckboxProps = CheckboxInputProps & {
  label: string | JSX.Element;
  error?: string;
};

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ style, className, label, id, error, hasError, subLabel, ...props }, ref) => {
    return (
      <FieldContainer style={style} className={className}>
        <Box>
          <CheckableInputLabel htmlFor={id}>
            <Flex gap={8} alignItems="center">
              <CheckboxInput
                ref={ref}
                id={id}
                {...props}
                hasError={hasError || !!error}
                aria-labelledby={[props["aria-labelledby"], !!error && `error_${id}`]
                  .filter(isTruthy)
                  .join(" ")}
              />
              <Flex gap={4} direction="column" grow={1}>
                {label}
                {subLabel && <SubLabel>{subLabel}</SubLabel>}
              </Flex>
            </Flex>
          </CheckableInputLabel>
        </Box>
        {error && <InputErrorMessage id={`error_${id}`}>{error}</InputErrorMessage>}
      </FieldContainer>
    );
  }
);
