import { t } from "i18next";

enum PrerequisiteDataKeys {
  PROCLIENT = "proclient",
  CAREGIVER = "caregiver",
  CONTACT = "contact",
  CONTRACT = "contract",
  CGPC = "cgpc",
  TRAINING = "training",
}

const prerequisiteDataToText: Record<string, string> = {
  proclientRum: "rum",
  proclientIban: "iban",
  proclientBankAccount:
    'compte bancaire (vous pouvez le renseigner sur la fiche du bénéficiaire, section "Modalités de facturation")',
  proclientBic: "bic",
  proclientGender: "genre",
  proclientFirstName: "prénom",
  proclientLastName: "nom de famille",
  proclientEmail: "adresse mail",
  proclientPlaceJson: "adresse",
  proclientPhones: "téléphone",
  proclientXimiId: "pas de fiche Ximi associée",
  proclientBirthday: "date de naissance à remplir sur Hassibot",
  proclientUrssaf: "numéro d'affiliation URSSAF",
  proclientAgencyZone:
    "le code postal de son adresse n’est rattaché à aucune agence. Veuillez demander à votre Directeur Régional (ou ADR) d'associer ce code postal à une agence",
  proclientLegalStructure:
    "la structure juridique n'a pas été trouvée. Veuillez vérifier que le bénéficiaire est bien rattaché à une agence.",

  caregiverIdCardNotFound: "document d'identité à uploader sur Hassibot",
  caregiverRibNotFound: "rib à uploader sur Hassibot",
  caregiverIdCardExpiration:
    "expiration de la carte d'identité à renseigner sur Ximi. Cette information sera disponible sur Hassibot 5 à 10 minutes après l'avoir renseignée sur Ximi, et après avoir rechargé la page Hassibot.",
  caregiverIdCardNumber:
    "numéro de la carte d'identité à renseigner sur Ximi. Cette information sera disponible sur Hassibot 5 à 10 minutes après l'avoir renseignée sur Ximi, et après avoir rechargé la page Hassibot.",
  caregiverIdCardNumberExpiration:
    "numéro de carte d’identité, date d’expiration de la carte. Ces informations sont à renseigner ici sur Ximi. Ces informations seront disponibles sur Hassibot 5 à 10 minutes après les avoir renseignées sur Ximi, et après avoir rechargé la page Hassibot.",
  caregiverFirstName: "prénom",
  caregiverLastName: "nom de famille",
  caregiverEmail: "adresse mail",
  caregiverPlaceJson: "adresse",
  caregiverSsn: "numéro de sécurité sociale à remplir sur Hassibot",
  caregiverIban: "numéro iban à renseigner sur Ximi",
  caregiverBic: "numéro bic à renseigner sur Ximi",
  caregiverBirthday: "date de naissance à remplir sur Hassibot",

  contactFirstName: "prénom",
  contactLastName: "nom de famille",
  contactMail: "mail",
  contactPhone: "téléphone",
  contactPlace: "adresse",
  contactRelationship: "relation",
  contactRelationshipMore: "relation étendue",

  contractType: "type de contrat",
  contractStartOn: "date de debut du contrat",
  contractEndOn: "date de fin du contrat",

  cgpcInitialSalary: "salaire horaire (ximi)",

  trainingId: "formation",
  trainingSessionId: "session de formation",
  trainingAlreadyOngoing: `l'${t("caregiver")} a déjà une formation en cours`,
};
const DEFAULT_MESSAGE = "Erreur inconnue, veuillez contacter la tech";
const formatProclientError = (errorKeys: string[]): string | undefined => {
  if (!errorKeys.some(k => isProclientError(k))) return undefined;
  return `Il manque les informations suivantes liées au bénéficiaire : ${errorKeys
    .filter(errorKey => isProclientError(errorKey))
    .map(item => prerequisiteDataToText[item] || DEFAULT_MESSAGE)
    .join(", ")}`;
};

const transform = (errorKeys: string[]): string[] => {
  return ["caregiverIdCardNumber", "caregiverIdCardExpiration"].every(e => errorKeys.includes(e))
    ? [
        "caregiverIdCardNumberExpiration",
        ...errorKeys.filter(
          k => k !== "caregiverIdCardNumber" && k !== "caregiverIdCardExpiration"
        ),
      ]
    : errorKeys;
};

const formatCaregiverError = (errorKeys: string[]): string | undefined => {
  const filtredErrorKeys = transform(errorKeys);
  if (!filtredErrorKeys.some(k => isCaregiverError(k))) return undefined;
  return `Il manque les informations suivantes liées à l'${t("caregiver")} : ${filtredErrorKeys
    .filter(filtredErrorKeys => isCaregiverError(filtredErrorKeys))
    .map(item => prerequisiteDataToText[item] || DEFAULT_MESSAGE)
    .join(", ")}`;
};

const formatContactError = (errorKeys: string[]): string | undefined => {
  if (!errorKeys.some(k => isContactError(k))) return undefined;
  return `Il manque les informations suivantes liées au contact : ${errorKeys
    .filter(errorKey => isContactError(errorKey))
    .map(item => prerequisiteDataToText[item] || DEFAULT_MESSAGE)
    .join(", ")}`;
};
const formatCaregiverProclientError = (errorKeys: string[]): string | undefined => {
  if (!errorKeys.some(k => isCGPCError(k))) return undefined;
  return `Il manque les informations suivantes liées à la relation contractuelle : ${errorKeys
    .filter(errorKey => isCGPCError(errorKey))
    .map(item => prerequisiteDataToText[item] || DEFAULT_MESSAGE)
    .join(", ")}`;
};

const formatContractError = (errorKeys: string[]): string | undefined => {
  if (!errorKeys.some(k => isContractError(k))) return undefined;
  return `Il manque les informations suivantes liées au contrat : ${errorKeys
    .filter(errorKey => isContractError(errorKey))
    .map(item => prerequisiteDataToText[item] || DEFAULT_MESSAGE)
    .join(", ")}`;
};

const formatTrainingError = (errorKeys: string[]): string | undefined => {
  if (!errorKeys.some(k => isTrainingError(k))) return undefined;
  return `Il y a des erreurs par rapport à la formation : ${errorKeys
    .filter(errorKey => isTrainingError(errorKey))
    .map(item => prerequisiteDataToText[item] || DEFAULT_MESSAGE)
    .join(", ")}`;
};

const isCaregiverError = (errorKey: string): boolean =>
  errorKey.includes(PrerequisiteDataKeys.CAREGIVER);
const isProclientError = (errorKey: string): boolean =>
  errorKey.includes(PrerequisiteDataKeys.PROCLIENT);
const isContactError = (errorKey: string): boolean =>
  errorKey.includes(PrerequisiteDataKeys.CONTACT);
const isContractError = (errorKey: string): boolean =>
  errorKey.includes(PrerequisiteDataKeys.CONTRACT);
const isCGPCError = (errorKey: string): boolean => errorKey.includes(PrerequisiteDataKeys.CGPC);
const isTrainingError = (errorKey: string): boolean =>
  errorKey.includes(PrerequisiteDataKeys.TRAINING);

export const formatPrerequisiteData = (errorKeys: string[]): string[] =>
  [
    formatProclientError(errorKeys),
    formatCaregiverError(errorKeys),
    formatContactError(errorKeys),
    formatContractError(errorKeys),
    formatCaregiverProclientError(errorKeys),
    formatTrainingError(errorKeys),
  ].filter(item => item !== undefined) as string[];
