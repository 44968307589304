import { captureMessage } from "@sentry/react";
import { t } from "i18next";
import { OhApi } from "siayuda";
import { formatPrerequisiteData } from "./missing-prerequisite-data";

export const NOT_MAPPED = "Erreur inconnue. Veuillez envoyer un mail à bugs@ouihelp.fr";

const errorMessages = {
  INVALID: "Champ incorrect",
  REQUIRED: "Veuillez renseigner ce champ",
  PHONE_ALREADY_USE: "Ce numéro de téléphone est déjà attribué à quelqu'un",
  PHONE_REFUSED: "Le numéro de téléphone est incorrect",
  CLOSED_CALL_EVALUATION_CAN_NOT_BE_UPDATED: "Cette interface d'appel est fermée",
  PHONE_INVALID:
    "Le numéro de téléphone est invalide. Veuillez préciser l'indicatif du numéro (Ex: +33). Sinon, vérifiez que le numéro est bien attribué",
  CAREGIVER_ALREADY_HAVE_XIMI_ID: "L'intervenant a déjà un compte Ximi associé",
  XIMI_ID_ALREADY_BOUND_TO_OTHER: "Cet identifiant Ximi est déjà associé à un autre intervenant",
  PROCESSING_ERROR: "Impossible de trouver un visage dans l'image envoyée",
  PROCLIENT_ALREADY_HAVE_REFERENCE_CONTACT: "Ce bénéficiaire a déjà un contact de référence",
  CAN_NOT_INSERT_OUIHELP_SERVICE_ON_PRESCRIBER:
    "Impossible d'attribuer un service Ouihelp à un prescripteur",
  CANNOT_CANCEL_DEBRIEFED_EVENT:
    "Impossible d'annuler un évènement déjà débriefé, veuillez rafraichir la page",
  EVENT_ALREADY_DEBRIEFED: "Cet évènement a déjà été débriefé",
  CAREGIVER_INVALID_SSN: "Ce numéro de sécurité sociale est invalide",
  FORBIDDEN_CHARACTERS: "Votre texte contient des caractères non autorisés",
  MAIL_ALREADY_USE: "Le mail fourni est déjà utilisé",
  FILE_TYPE:
    "Le format du fichier importé n'est pas accepté. Veuillez importer un fichier au format PDF.",
  IBAN_BIC_AND_ACCOUNT_OWNER_FULL_NAME_REQUIRED_TOGETHER:
    "Veuillez fournir l'intégralité des données bancaires",
  IBAN_BIC_MISMATCH: "L'IBAN et le BIC ne correspondent pas",
  CESU_PAYMENT_WITHOUT_CESU_SETTINGS:
    "Ce bénéficiaire n’a pas CESU comme mode de règlement principal." +
    " Éditez les modalités de facturation du bénéficiaire pour ajouter de nouveaux règlements par CESU.",
  ONGOING_CALL_EVALUATION_ALREADY_EXISTS_FOR_PROCLIENT:
    "Une instance d'appel existe déjà pour ce prospect",
  CANCELLATION_REASON_REQUIRED: "Veuillez fournir une raison d'annulation",
  CANCELLATION_REASON_MORE_REQUIRED:
    "Veuillez fournir plus d'informations sur la raison d'annulation",
  UNKNOWN_USER: "Ce compte utilisateur n'existe pas",
  DELETED_USER: "Ce compte utilisateur a été supprimé",
  LOGIN_ERROR: "Erreur de connexion inconnue",
  PDF_IS_CORRUPTED:
    "Le fichier que vous essayez d'ouvrir semble être corrompu et ne peut pas être utilisé",
  PRICE_TOO_SMALL: "Le prix est trop bas",
  PRICE_TOO_EXPENSIVE: "Le prix est trop élevé",
  PRICE_MISMATCH: "Le prix est différent de celui attendu",
  STRUCTURE_READ_ONLY_FROM_HB:
    "Cette structure est gérée depuis un CRM externe et ne peut être modifiée sur Hassibot.",
  WRONG_VALUE: "La valeur est incorrecte",
  PROCLIENT_INVALID_STAGE: "Le bénéficiaire n'est plus dans le bon statut",
  COMPLETED_VEB_EVALUATION_CAN_NOT_BE_UPDATED: "La VEB ne peut plus être mise à jour",
  CANNOT_UPDATE_CARTS_IF_QUOTE_DOCUMENT_ALREADY_EXISTS:
    "Supprimez d'abord le devis pour mettre à jour l'accompagnement",
  CANNOT_UPDATE_MONET_SETTINGS_DATA_IF_SEPA_WARRANT_DOCUMENT_ALREADY_EXISTS:
    "Un mandat SEPA existe déjà",
  CAN_NOT_DEBRIEF_EVENT: "La visite a déjà été débriefée ou n'existe plus",
  PAYMENT_SETTING_ALREADY_SET_FOR_PROCLIENT_MEMBER:
    "Les paramètres de paiement sont déjà renseignés",
  PROSPECT_TYPE_UPDATE_NOT_ALLOWED: "Impossible de mettre à jour le type de prospect",
  URSSAF_REJECTION_ERROR: "Erreur lors de la validation URSSAF",
  CANNOT_SEND_ANOTHER_VALIDATION_MAIL_YET: "Impossible d'envoyer un autre mail de validation",
  WRONG_PROCLIENT_TYPE: "Mauvais type de bénéficiaire",
  COLLABORATOR_IS_NOT_OPS: "Le collaborateur ne travaille pas en agence",
  EXISTING_BLACKLIST_COUPLE: `Le couple ${t("caregiver_short")}/BNF est déjà non recommandé`,

  COLLABORATOR_IS_NOT_BUSINESS_DEVELOPER: "Le collaborateur n'est pas business developer",
  UNCOMPLETED_CALL_EVALUATION_ALREADY_EXISTS:
    "Une interface de programmation de VEB est déjà en cours pour ce prospect",
  IN_ENVELOPE_DOCUMENT_IS_READ_ONLY:
    "Les documents présents dans une enveloppe ne peuvent pas être modifiés",
  INVALID_PROSPECT_TYPE_CHANGE: "Le changement de type du prospect est incorrect",
  NO_EXTRA_FIELD: "Une erreur est survenue, si cela persiste, merci de contacter la tech",
  PROCLIENT_WITHOUT_ZONE: "Le bénéficiaire n'est associé à aucune zone.",
  MUST_BE_EXACTLY_ONE_ISSUE_FOR_CLOSING: "Il doit y avoir exactement un incident pour clôturer.",
  INTERVENTION_ALREADY_ASSIGNED: "L'intervention a déjà été assignée.",
  MISSING_PROCLIENT_XIMI_DATA: "Les données Ximi pour le bénéficiaire sont manquantes.",
  INTERVENTION_DOES_NOT_EXIST: "L'intervention demandée n'existe pas.",
  INTERVENTION_NOT_VACANT: "L'intervention n'est pas à pourvoir.",
  FUTURE_VEB_OR_TOO_LATE_VEB: "La VEB n'est plus ou pas encore annulable.",
  CITY_NOT_FOUND: "La ville de l'adresse du bénéficiaire n'existe pas dans DSIT",
  LEGAL_ENTITY_MODE_NOT_SUPPORTED:
    "Cette fonctionnalité n'est pas accessible pour le mode prestataire.",
  XIMI_REGISTRATION_FAILED: "La création du profil Ximi a échoué.",
  ENTITY_ORIGIN_BINDING_ALREADY_EXISTS_FOR_TARGET_SOURCE:
    "Une personne ne peut pas être à la fois en origine et en remontée de filière d’une autre personne, modifier l’origine afin de poursuivre.",
  ENTITY_NOT_FOUND_FOR_ENTITY_TYPE: "La personne à l'origine n'a pas été trouvée.",
  STRUCTURE_ASSOCIATION_READ_ONLY_FROM_HB: "La structure associée est gérée depuis un CRM externe.",
  PRESCRIBER_ASSOCIATION_READ_ONLY_FROM_HB:
    "Le prescripteur associé est géré depuis un CRM externe.",
  READ_ONLY_FROM_HB:
    "L'entitée est gérée depuis un CRM externe et ne peut être modifiée sur Hassibot.",
  PRESCRIBER_READ_ONLY_FROM_HB:
    "Ce prescripteur est géré depuis un CRM externe et ne peut être modifié sur Hassibot.",
  STRUCTURE_IN_ERROR_STATUS: "Cette structure possède des erreurs de synchro avec Hubspot.",
  CANNOT_HAVE_MORE_THAN_ONE_FAVORITE_PRODUCT:
    "Vous ne pouvez pas avoir plus d'un accompagnement favori.",
  CANT_ASSIGN_A_RESOLVED_INTERVENTION: "Cette intervention a déjà été résolue",
  CANNOT_UPDATE_EVENT_WHEN_EVENT_SCHEDULED:
    "Impossible de modifier un évènement lorsqu'il est déjà planifié",
  // ---------- Documents signables ----------
  DOCUMENTS_DOES_NOT_MEET_SPECIFICATIONS_TO_BE_SIGNED:
    "Les documents sélectionnés ne remplissent pas les conditions nécessaires.",
  CANNOT_GENERATE_DOCUMENT_FOR_COUPLES: "Impossible de générer un document pour un couple",
  SIGNATORY_NOT_FOUND: "Signataire inconnu",
  UNKNOWN_OUIHELP_REPRESENTATIVE: "Représentant de ouihelp inconnu. Vérifiez l'email associé.",
  DOCUMENTS_NOT_FOUND: "Documents associés inconnu. Vérifier qu’ils sont bien générés sur Hassibot",
  REPRESENTATIVE_NOT_KNOWN_BY_DOCUSIGN:
    "Aucun compte Docusign associé à ce représentant Ouihelp. Merci de créer le compte associé, si il vient d'être crée, il sera actif sur Hassibot d'ici 1h.",
  MONET_BANK_ACCOUNT_ALREADY_USED: "Le compte bancaire actuel est déjà associé à un mandat SEPA",
  GENERATE_DISABLED_FOR_COUPLE: "La génération de document n'est pas gérée pour les couples",
  BANK_ACCOUNT_ALREADY_BOUND_TO_DOCUMENT:
    "La section \"Mandat SEPA\" contient déjà un document : s'il s'agit effectivement d'un mandat SEPA, inutile d'en générer un deuxième.\nS'il s'agit d'une erreur, veuillez déplacer le document existant dans la section \"Autres documents\", puis générer ou ajouter le mandat SEPA",
  DELEGATE_CANT_BE_REPRESENTATIVE:
    "Le référent ne peut pas signer sa propre délégation de pouvoir (le bénéficiaire et le référent doivent être deux personnes distinctes)",
  CANNOT_SEND_TO_XIMI_FOR_COUPLES: "Impossible d'envoyer un document sur Ximi pour un couple",
  CANNOT_UPDATE_ORIGIN_ENTITY_FOR_HUBSPOT_PROCLIENT:
    "Impossible de modifier le prescripteur à l'origine d'un bénéficiaire Hubspot",
  COLLABORATOR_NOT_KNOWN_BY_DOCUSIGN: "Vous n'avez pas de compte DocuSign",
  NOT_A_QUOTATION_DOCUMENT: "Ce document n'est pas un devis",
  ALREADY_SENT_XIMI_QUOTE_DOCUMENT: "Ce document a déjà été envoyé sur Ximi",
  MISSING_GEOLOCATION_DATA:
    "Les données de géolocalisation sont manquantes. Veuillez activer la géolocalisation pour continuer.",
  MISSING_CONTRACTOR_BANK_DATA:
    "Les informations bancaires du bénéficiaire sont manquantes. Vous pouvez les compléter sur ximi. " +
    "Une fois celles-ci ajoutées, vous pourrez générer le mandat SEPA (la synchronisation peut prendre une dizaine de minutes).",

  // ---------- planning ----------
  PLAN_DURATION_TOO_LONG: "La durée de ce plan dépasse la plage horaire saisie",
  DATE_TO_MUCH_IN_THE_FUTURE:
    "Vous ne pouvez pas rechercher avec une date de début au delà de 4 semaines.",

  // ---------- shared house splitting run errors ----------
  ALREADY_ONGOING_SYNCHRONIZATION_FOR_SHARED_HOUSE:
    "Une synchronisation est déjà en cours pour cette maison partagée à cette période.",
  INVALID_SPLITTING_RUN_STATUS_FOR_SYNCHRONIZATION:
    "Impossible de lancer la synchronisation pour cette période (un petit mot à la tech plz).",
  SHARED_HOUSE_SPLITTING_RUN_SYNCHRO_ERROR:
    "Des erreurs sont intervenues lors de la dernière tentative de synchronisation. Veuillez essayer de la relancer.",
};

const errorMessagesWithExtra = {
  PRICE_TOO_SMALL: ({ min }: { min: number }) =>
    `Le prix doit être supérieur ou égal à ${min / 100}€`,
  PRICE_TOO_EXPENSIVE: ({ max }: { max: number }) =>
    `Le prix doit être inférieur ou égal à ${max / 100}€`,
  PRICE_MISMATCH: ({ value }: { value: number }) => `Le prix doit être égal à ${value / 100}€`,

  // ---------- Documents signables ----------
  MISSING_PREREQUISITE_DATA: (extraError?: string[] | { missingData: string[] }) => {
    return extraError && !Array.isArray(extraError)
      ? formatPrerequisiteData(extraError.missingData).join("\n")
      : "Une information est manquante pour générer le document";
  },
  MISSING_DATA_FOR_SIGNATORY: (extraError?: string[] | { missingData: string[] }) => {
    if (!Array.isArray(extraError)) return "";
    else {
      const translateField = {
        mail: "Email",
      };
      return `${extraError
        ?.map(k => translateField[k])
        .join(
          ", "
        )} du signataire sélectionné manquant. Veuillez renseigner le/les champ.s manquant.s`;
    }
  },
};

/* for more accurate error messages, we consume the extra to build message
 * use this instead of ErrorsMessages for better messages
 */
export const accurateErrorsMessages = {
  ...errorMessages,
  ...errorMessagesWithExtra,
};

export type OhError = keyof typeof accurateErrorsMessages;

export const handleUnknownError = (type: string) => {
  captureMessage("Received unknown error type : " + type);
  return NOT_MAPPED;
};

export const formatError = (
  error: string | undefined,
  extraMessages?: Record<string, string>
): string | undefined => (error ? messageFor(extraMessages)(error) : undefined);

export const messageFor =
  (extraMessages?: Record<string, string>) =>
  (key: string | undefined): string => {
    if (!key) {
      return NOT_MAPPED;
    }
    return extraMessages && key in extraMessages
      ? (extraMessages[key] as string)
      : key in errorMessages
        ? (errorMessages[key] as string)
        : NOT_MAPPED;
  };

export const handleDocumentGlobalError = (resp: OhApi.GlobalError): string[] => {
  const errorMessage = accurateErrorsMessages[resp.error];
  const error =
    typeof errorMessage === "function"
      ? errorMessage(resp.extra as string[] | { missingData: string[] })
      : errorMessage;

  return [error || NOT_MAPPED];
};
