import { color } from "emoreg/const";
import styled from "styled-components";

const Container = styled.button({
  padding: 8,
  borderRadius: 8,
  borderWidth: 0.5,
  borderStyle: "solid",
  borderColor: color.grey.base,
  transition: "border-color 200ms ease-in-out",
  backgroundColor: color.white,

  "&:hover": {
    borderColor: color.blue.base,
  },

  "&:disabled": {
    borderColor: "transparent",
  },
});

Container.displayName = "ButtonCardContainer";

export const ButtonCardStyle = Container;
